import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import 'firebase/firestore';
import ReactGA from 'react-ga';

export default function Analytics(){
  useEffect(() => {
    ReactGA.initialize('G-BHXCJNQNET'); // Firebase Tracking ID-vel inicializálás
    const firebase = require('firebase/app');
    require('firebase/analytics');
    firebase.analytics();
    ReactGA.pageview(window.location.pathname);
  }, []);

  const AnalyticsDetails = (item) => {
    ReactGA.event({
      categorY: '/:collectionId/:productId',
      action: 'Viewed', 
      label: item.name,
      value: item.price,
    });
  };

  return(
    <div>

    </div>
  );
}