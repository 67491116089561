import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'; 
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "christinetaylorart-af705.firebaseapp.com",
  projectId: "christinetaylorart-af705",
  storageBucket: "christinetaylorart-af705.appspot.com",
  messagingSenderId: "47246775246",
  appId: "1:47246775246:web:0bd2ce2eaa3b639886519f",
  measurementId: "G-BHXCJNQNET"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);
export default app;