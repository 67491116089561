import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import RentCollection from '../components/rentcollection/RentCollection';
import { useParams } from 'react-router-dom';
import BreadCrumb from "../components/breadcrumb/BreadCrumb";

export default function RentCar() {
  const { category } = useParams();

  return (
    <>
      <Navbar />
      <BreadCrumb />
      <RentCollection category={category} />
      <Footer />
    </>
  );
}