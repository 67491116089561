import { createContext, useReducer, useEffect } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
    currentUser: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

    useEffect(() => {
        // Retrieve the user object from localStorage
        const storedUser = JSON.parse(localStorage.getItem("user"));

        if (storedUser && typeof storedUser === 'object') {
            // Set the currentUser to the retrieved user object
            dispatch({ type: "user", payload: storedUser });
        }
    }, []);

    useEffect(() => {
        // Update localStorage with the current user as a JSON string
        localStorage.setItem("user", JSON.stringify(state.currentUser));
    }, [state.currentUser]);

    return (
        <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};
