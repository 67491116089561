import BreadCrumb from "../components/breadcrumb/BreadCrumb"
import Footer from "../components/footer/Footer"
import Navbar from "../components/navbar/Navbar"
import Policy from "../components/policy/Policy"

export default function PrivacyPolicy(){
    return(
        <>
            <Navbar />
            <BreadCrumb />
            <Policy />
            <Footer />
        </>
    )    
}