import React from "react";
import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero";
import RentCar from "../components/rentcar/RentCar";
import Footer from "../components/footer/Footer";
import Contact from "../components/contact/Contact";
import CompanyDescription from "../components/companydetail/CompanyDescription";
import CompanyQestions from "../components/companydetail/CompanyQestions";
import NextInformation from '../components/nectinformation/NextInformation'
import FeaturedProducts from "../components/featuredproducts/FeaturedProducts";

export default function Home(){
    return(
        <>
            <Navbar />
            <Hero />
            <Contact />
            <RentCar />
            <CompanyDescription />
            <FeaturedProducts />
            <CompanyQestions />
            <NextInformation />
            <Footer />
        </>
    )
}