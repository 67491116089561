import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import Picasso from '../../assets/companydescription/picasso-39592_640 (1).png'
import PabloPicasoImg from '../../assets/companydescription/pablo_picasso.png'
import './CompanyStyles.css';

export default function CompanyDescription() {

  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'right',
      distance: '80px',
      duration: 2000,
      reset: false // Ne állítsa vissza az animációkat a görgetés után
    });
    sr.reveal('.company-picture-img', {delay: 250, origin:'right'});
    sr.reveal('.descrpiton-company', {delay: 300, origin:'left'});
    sr.reveal('.company-written-img', {delay: 380, origin:'right'});
    return () => {
      sr.destroy();
    };
  }, [])

  return (
    <div className='company-description py-5'>
      <div className='container py-5 my-4'>
        <div className="company-group">
          <div>
            <img className='company-picture-img' src={PabloPicasoImg} alt="" />
          </div>
          <h1 className="descrpiton-company">"Egy ötlettel kezdem <br /> és aztán valami más lesz belőle."</h1>
          <img className="company-written-img" src={Picasso} alt="" />
        </div>
      </div>
    </div>
  );
}