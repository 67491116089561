import React, { useEffect, useState } from 'react';
import './BreadCrumbStyles.css';
import { Link, useLocation } from 'react-router-dom';

const routeNameMapping = {
  rentcar: 'Kollekció',
  aboutus: 'Rólam',
  contact: 'Kapcsolat',
  kozep: 'Kollekció',
  privacypolicy: 'Adatvédelmi szabályzat'
};

export default function BreadCrumb() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    const currentPath = paths[paths.length - 1];
    setPageTitle(routeNameMapping[currentPath] || currentPath);
  }, [location.pathname]);

  return (
    <div className="breadcrumb-component">
      <div className="container py-5 d-flex justify-content-center align-items-center">
        <nav aria-label="breadcrumb-card py-5">
          <h1 className="breadcrumb-title text-center fs-1">{pageTitle}</h1>
        </nav>
      </div>
    </div>
  );
}
