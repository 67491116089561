import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './DashCusStyles.css';
import {
  db,
  storage,
} from '../../firebase';
import {
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

export default function DashEdited({ togglePopup, selectedVehicleData }) {
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('olcso');
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [paintingsize, setPaintingSize] = useState('');
  const [imageUpload, setImageUpload] = useState([]);

  useEffect(() => {
    if (selectedVehicleData) {
      setName(selectedVehicleData.name || '');
      setCountry(selectedVehicleData.country || '');
      setState(selectedVehicleData.state || '');
      setDescription(selectedVehicleData.description || '');
      setPaintingSize(selectedVehicleData.paintingsize || '');
      setSelectedCollection('olcso');
      setUploadedImageUrls(selectedVehicleData.imageUrls || []);
    }
  }, [selectedVehicleData]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const uploadedImages = [];

    for (let i = 0; i < files.length; i++) {
      uploadedImages.push(files[i]);
    }

    setImageUpload(uploadedImages);
  };

  const handleModify = async (e) => {
    e.preventDefault();

    try {
      if (!selectedVehicleData) {
        console.error('Selected vehicle data is undefined or null.');
        return;
      }

      const uploadTasks = imageUpload.map((file) => {
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {},
            (error) => {
              console.error('Error uploading image:', error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      });

      const downloadURLs = await Promise.all(uploadTasks);

      const productRef = doc(db, selectedCollection, selectedVehicleData.id);

      await updateDoc(productRef, {
        name: name,
        country: country,
        state: state,
        description: description,
        imageUrls: [...uploadedImageUrls, ...downloadURLs],
        paintingsize: paintingsize,
        timeStamp: serverTimestamp(),
      });

      setIsUploadSuccess(true);
      setUploadedImageUrls(prevState => [...prevState, ...downloadURLs]);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleCollectionChange = (e) => {
    setSelectedCollection(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePaintingSizeChange = (e) => {
    setPaintingSize(e.target.value);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...uploadedImageUrls];
    updatedImages.splice(index, 1);
    setUploadedImageUrls(updatedImages);
  };

  const handleDeleteImages = () => {
    setUploadedImageUrls([]);
  };

  return (
    <div className='modal-background py-5 overflow-y-auto'>
      <div className='container'>
        <div className="scrollable-content overflow-auto mt-5">
          <button onClick={togglePopup} className='btn btn-primary my-2' style={{ float: 'right' }}>
            <AiOutlineClose />
          </button>
          <div className='card card-dashadd'>
            {isUploadSuccess && (
              <div className='alert alert-success mt-2 text-center w-100 '>
                <p className='fw-bolder'>Sikeresen módosítottad az adatokat!</p>
              </div>
            )}
            <form onSubmit={handleModify} className='card-body'>
              <div className='row g-2'>
                {uploadedImageUrls.length > 0 && (
                  <div>
                    <p>Már feltöltött képek:</p>
                    <div className="d-flex flex-row mb-3">
                      {uploadedImageUrls.map((url, index) => (
                        <div key={index} className="card" style={{borderRadius:'0px 0px 10px 10px'}}>
                          <img
                              src={url}
                              className="card-img-top"
                              alt={`Uploaded Image ${index}`}
                              style={{ maxWidth: '100%', maxHeight: '100px', marginRight: '10px', borderRadius:'0px 0px 0px 0px' }}
                            />
                          <div>
                            <button
                              type='button'
                              className='btn btn-danger w-100'
                              style={{borderRadius:'0px 0px 10px 10px'}}
                              onClick={() => handleDeleteImage(index)}
                            >
                              Törlés
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button type='button' className='btn btn-danger mt-2' onClick={handleDeleteImages}>
                      Képek Törlése
                    </button>
                  </div>
                )}

                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <input type='file' className='form-control' onChange={handleImageChange} multiple />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <input className='form-control' type='text' placeholder='Festmény címe' value={name} onChange={handleNameChange} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <input className='form-control' type='text' placeholder='Eredeti Ár' value={country} onChange={handleCountryChange} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <input className='form-control' type='text' placeholder='Akciós Ár' value={state} onChange={handleStateChange} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <input className='form-control' type='text' placeholder='Festmény méret pl: 100X100' value={paintingsize} onChange={handlePaintingSizeChange} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                  <select className='form-control' value={selectedCollection} onChange={handleCollectionChange}>
                    <option value='olcso'>Válassz Kollekciót</option>
                    <option value='kozep'>Közep</option>
                  </select>
                </div>
                <div className='col-12'>
                  <textarea
                    className='form-control w-100'
                    style={{
                      maxHeight: '300px',
                      minHeight: '200px',
                    }}
                    value={description}
                    onChange={handleDescriptionChange}
                  ></textarea>
                </div>
              </div>
              <button type='submit' className='btn btn-primary mt-2'>
                Módosítás
              </button>
            </form>
          </div>
        </div>
        
      </div>
    </div>
  );
}

