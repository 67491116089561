import React, {useState, useEffect} from 'react';
import {BsFillCalendarEventFill} from 'react-icons/bs'
import {BiSolidPhoneCall} from 'react-icons/bi'
import Book from '../booking/Book'
import Sliders from './Sliders';
import './CarDetailStyles.css';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import AllFeaturedProducts from '../featuredproducts/AllFeaturedProducts';

export default function CarDetail({product, productId, setProduct, collectionId}){
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);  
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                if (!collectionId || !productId) {
                console.error('Invalid collectionId or productId');
                return;
            }
            const docRef = doc(db, collectionId, productId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProduct({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.error('Product not found');
            }
            } catch (error) {
                console.error('Error fetching product:', error.message);
          }
        };
        fetchProduct();
    }, [collectionId, productId]);

    if (!product) {
        return <div>Loading...</div>;
    }

    return(
        <div className="cardetail">
            <div className="container my-5">
            <h3 className="cardetail-information">Részletes információk</h3>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 ">
                    <div className="col">
                        <div className='carousel-slide'>
                            <div className="carousel-inner" role="listbox">
                                <Sliders imageUrls={product.imageUrls}/>
                            </div>    
                        </div>
                    </div>
                    <div className="col">
                        <p className="cardetail-backdown">
                            <Link to="/" className='text-decoration-none mx-1 text-black'>Főoldal</Link> <span>/</span>
                            <Link to="/kozep" className='text-decoration-none mx-1 text-black'>Kollekció</Link> <span>/ </span> 
                             Termék oldal
                        </p>
                        <h2 className="cardetail-name">{product.name}</h2>
                        <div className="d-flex cardetail-description">
                            <p className={`categorylist-description mx-2 ${product.state ? 'featured-underline' : ''}`}>{product.country} Ft</p>
                            {product.state && (
                                <p className='categorylist-description mx-2'>{product.state} Ft</p>
                            )}
                        </div>
                        <p className="cardetail-paintingsize">Méret: {product.paintingsize}</p>
                        <div className="mt-3 cardetail-booking">
                            <button 
                                onClick={toggleMenu} 
                                className="button-unique mx-1" 
                                style={{
                                    backgroundColor:'#fbdb6a',
                                    color:'black'
                                }}
                            >Megveszem</button>
                        </div>
                        {isOpen && (
                            <Book product={product}  toggleMenu={toggleMenu} />
                        )}
                        <div className="d-grid price py-3">
                        <hr />
                          <p className="cardetail-description"><span className="cardetail-bold-desc">Leírás:</span> <br /> {product.description}</p>
                        </div>
                    </div>
                </div>
                <div className='my-5 py-5'>
                    <AllFeaturedProducts/>
                </div>
            </div>
        </div>
    )
}