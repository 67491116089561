
import React, { useState, useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';
import './FeaturedStyles.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './FeaturedStyles.css'


export default function FeaturedProducts() {
  const [peopleData, setPeopleData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'kozep'));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPeopleData(data);
        console.log('Data fetched from Firebase successfully');
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'right',
      distance: '80px',
      duration: 2000,
      reset: false // Ne állítsa vissza az animációkat a görgetés után
    });
    sr.reveal('.card-category', { delay: 400, origin: 'right' });
    sr.reveal('.cardetail-information', { delay: 340, origin: 'left' });
    return () => {
      sr.destroy();
    };
  }, [])  

  return (
    <div className="featuredproducts py-5">
      <div className="container">
        <Swiper
          slidesPerView={1}
          style={{
            '--swiper-pagination-color': '#d9a95b ',
          }}
          spaceBetween={10}
          pagination={{ clickable: true }}
          modules={[ Pagination]}
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 2, spaceBetween: 10 },
            1024: { slidesPerView: 4, spaceBetween: 10 },
          }}
          className="mySwiper py-5"
        >
          {peopleData.map((person) => (
            <SwiperSlide key={person.id}>
              <Link to={`/${'kozep'}/${person.id}`} className="card-link text-decoration-none">
                <div className="card card-category">
                  <img src={person.imageUrls} className="card-img-top card-featured" alt={person.name} />
                  <div className="card-body text-center">
                    <div>
                      <h3 className="featured-product-title">{person.name}</h3>
                      <p className="featured-paintingsize">{person.paintingsize}</p>
                      <div className="d-flex justify-content-center mb-0">
                        <p
                          className={`featured-product-description mx-2 ${
                            person.state ? 'featured-underline' : ''
                          }`}
                        >
                          {person.country} Ft
                        </p>
                        {person.state && (
                          <p className="featured-product-description mx-1">{person.state} Ft</p>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}