import React from 'react';
import './TutiralStyles.css';


export default function TutorialVideo(){

    return(
        <div className='container'>
            <h2 className="tutorial-title">Termék feltöltése</h2>
            <div className="card mb-5 shadow-sm">
                <div className="card-body">
                    <div className="ratio ratio-16x9">
                        <iframe 
                            src="https://www.youtube.com/embed/ZZhoq55mbv0" 
                            allowFullScreen
                        ></iframe>
                    </div> 
                </div>
            </div>
            <h2 className="tutorial-title">Termék Szerkesztése</h2>
            <div className="card mb-5 shadow-sm">
                <div className="card-body">
                    <div className="ratio ratio-16x9">
                        <iframe 
                            src="https://www.youtube.com/embed/_t91J8hAFC8" 
                            title="YouTube video" 
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}