
import React, { useState, useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';
import './FeaturedStyles.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

export default function AllFeaturedProducts() {
  const [peopleData, setPeopleData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'kozep'));
            const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            }));
            const lastThreePeople = data.slice(Math.max(data.length - 4, 0));
            setPeopleData(lastThreePeople);
            console.log('Data fetched from Firebase successfully');
        } catch (error) {
            console.error('Error:', error);
        }
        };
        fetchData();
    }, []);


    useEffect(() => {
        const sr = ScrollReveal({
            origin: 'right',
            distance: '80px',
            duration: 2000,
            reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
            sr.reveal('.card-category', { delay: 400, origin: 'right' });
            sr.reveal('.cardetail-information', { delay: 340, origin: 'left' });
            return () => {
                sr.destroy();
        };
    }, [])

    return (
        <div className='my-5 py-5 allfeatured-product'>
            <h3 className="cardetail-information text-center">Kapcsolódó termékek</h3>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 g-2">
                {peopleData.map((person) => (
                <div className="col" key={person.id}>
                    <Link to={`/${'kozep'}/${person.id}`} className="card-link text-decoration-none">
                        <div className="card card-category">
                            <img
                                src={person.imageUrls}
                                className="card-img-top"
                                alt={person.name}
                            />
                            <div className="card-body text-center">
                                <div>
                                    <h3 className="featured-product-title">{person.name}</h3>
                                    <p className="featured-paintingsize">{person.paintingsize}</p>
                                    <div className="d-flex justify-content-center">
                                        <p className={`featured-product-description mx-2 ${person.state ? 'featured-underline' : ''}`}>
                                            {person.country} Ft
                                        </p>
                                        {person.state && (
                                            <p className="featured-product-description mx-1">{person.state} Ft</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                ))}
            </div>
        </div>
    );
}