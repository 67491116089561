import React, {useState} from 'react';
import {FaPlus} from 'react-icons/fa'
import DashAddPeople from './DashAddPeople';
import DashAddCustomers from './DashAddCustomers';

export default function DashAddSettings() {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  return (
    <>
      <div className="card my-3 shadow-sm">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-around">
            <input
              type="text"
              className="form-control mx-3"
              placeholder="Keresés..."
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div>
              <button className="btn btn-outline-primary mx-1" onClick={togglePopup}>
                <FaPlus />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <DashAddPeople togglePopup={togglePopup} />
      )}
      <DashAddCustomers searchText={searchText} />
    </>
  );
}