import React, {useEffect} from "react";
import ScrollReveal from "scrollreveal";
import HeroImage from '../../assets/contact/image.jpeg';
import './RentCarStyles.css';

export default function RentCar(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('.rent-car-title', {delay: 270, origin:'left'});
        sr.reveal('.rentcar-hr', {delay: 300, origin:'left'});
        sr.reveal('.rentcar-description', {delay: 330, origin:'left'});
        sr.reveal('.img-important', {delay: 300, origin:'right' });
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="rent-car py-5 ">
            <div className="container mb-sm-5 mb-md-0 mb-lg-0">
                <div 
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 align-items-center g-4 my-md-5 my-lg-5 my-sm-5 py-md-5 py-lg-5"
                    id="rowol1"
                >
                    <div className="col" >
                        <div className="text-lg-start contact-text-group" id="colol1">
                            <h1 className="rent-car-title ">Minden ami fontos lehet</h1>
                            <hr className="rentcar-hr" />
                            <p className="rentcar-description">Írd meg kívánságaid mérettel és színnel kapcsolatban. Esetleg csatolj néhány képet ami tetszik, vagy pár referencia fotómat. Ha van rá lehetőséged, készíthetsz pár fotót az otthonodról, hogy ihletet meríthessek az adott belsőépítészeti stílusból és színekből! Mindig igyekszem konkrét kívánságaid teljesíteni, de fontos számomra, hogy szabad kezet kaphassak, amikor festek. Kívánság szerint, kereteztetésre is van lehetőség. Modern keskeny lebegő keretek széles választékát kínálom, különböző színekben és anyagokban, amelyek gyönyörűen kiegészítik festményeimet.</p>
                        </div>
                    </div>
                    <div className="col order-first order-lg-last my-sm-5">
                        <img 
                            src={HeroImage} 
                            alt=""    
                            className="img-fluid img-important" 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}