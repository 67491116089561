import React, { useState } from 'react';
import Navbar from '../components/navbar/Navbar';
import RentCars from '../components/rent/RentCars';
import Footer from '../components/footer/Footer';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import { useParams } from 'react-router-dom';


export default function CategoryList() {
    const { collectionId } = useParams();
    const [products, setProducts] = useState([]);
    return(
        <>
            <Navbar />
            <BreadCrumb/>
            <RentCars 
                collectionId={collectionId} 
                products={products} 
                setProducts={setProducts} />
            <Footer />
        </>
    )
}
