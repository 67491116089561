import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import NotFound from "../components/notfound/NotFound";

export default function ErrorFound(){

    return(
        <>
            <Navbar />
            <BreadCrumb />
            <NotFound />
            <Footer />
        </>
    )
}