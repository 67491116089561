import React, { useEffect, useState } from 'react';
import './DashHomeStyles.css';
import { auth } from '../../firebase';

export default function DashHomeCard() {
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, []);

    return (
      <div className="container pt-5 my-5">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h1 className="dashome-title text-center fw-bolder">Üdvözöllek a Főoldalon!</h1>
                    {email && (
                        <h5 className="dashhome-winlogin text-center text-danger fw-bold">
                            <span className='text-success'>Sikeres bejelentkezés!</span>
                            <br />Üdvözöllek {email}
                        </h5>
                    )}
                </div>
            </div>
        </div>
    );
}