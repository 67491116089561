import React, { useEffect, useState, useContext } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga'
import Home from './routes/Home';
import Contact from './routes/Contact';
import About from './routes/About';
import RentCar from './routes/RentCar';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CategoryList from './routes/CategoryList';
import CarReview from './routes/CarReview';
import Login from './routes/Login';
import Dashboard from './routes/Dashboard';
import Analytics from './components/dashanalytics/Analytics'; // Importáljuk az Analytics komponenst
import { AuthContext } from './context/AuthContext';
import Loading from './components/loading/Loading';
import CookieBar from './components/cookiebar/CookieBar';
import PrivacyPolicy from './routes/PrivacyPolicy';
import ErrorFound from './routes/ErrorFound';

const TRACKING_ID = "G-BHXCJNQNET";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const RequireAuth = ({ children }) => {
    return currentUser ? (children) : <Navigate to="/login" />;
  };

  return (
    <HashRouter>
      <ScrollToTop />
      <CookieBar />
      {loading ? (
        <Loading setLoading={setLoading} loading={loading} />
      ) : (
        <Routes>
          <Route
            path='/'
            element={<Home />} // Pass visitorCount and productViews to Home component
          />
          <Route path='/rentcar' element={<RentCar />} />
          <Route path="/:collectionId" element={<CategoryList />} />
          <Route path="/:collectionId/:productId" element={<CarReview />} />
          <Route path='/aboutus' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/dashboard'
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          {/* Add route for Analytics component */}
          <Route path='/analytics' element={<Analytics />} />
          <Route path='*' element={<ErrorFound />} />
        </Routes>
      )}
    </HashRouter>
  );
}

export default App;
