import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import Paiting1 from '../../assets/gallery/painting1.jpg';
import Paiting2 from '../../assets/gallery/painting2.jpg';
import Paiting3 from '../../assets/gallery/painting3.jpg';
import Paiting4 from '../../assets/gallery/painting7.jpg';
import Paiting5 from '../../assets/gallery/painting5.jpg';
import Paiting6 from '../../assets/gallery/painting6.jpg';
import Paiting7 from '../../assets/gallery/painting7.jpg';
import Paiting8 from '../../assets/gallery/painting2.jpg';
import Paiting9 from '../../assets/gallery/painting9.jpg';

export default function AboutDescrpiton(){
    const images = [
        { src: Paiting1, alt: 'Image 1' },
        { src: Paiting2, alt: 'Image 2' },
        { src: Paiting3, alt: 'Image 3' },
        { src: Paiting4, alt: 'Image 1' },
        { src: Paiting5, alt: 'Image 2' },
        { src: Paiting6, alt: 'Image 3' },
        { src: Paiting7, alt: 'Image 1' },
        { src: Paiting8, alt: 'Image 2' },
        { src: Paiting9, alt: 'Image 3' },
    ];

    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#card-decs', { delay: 340, origin: 'right' });
        sr.reveal('#gallery-title', { delay: 300, origin: 'left' });
        sr.reveal('.about-hr', { delay: 320, origin: 'right' });
        return () => {
          sr.destroy();
        };
    }, [])
    
    return (
        <div className="about-description py-5" style={{overflowX:'hidden'}}>
            <div className="my-2">
                <h1 className="about-title text-center" id="gallery-title">Galéria</h1>
                <hr className="about-hr" />
            </div>
            <div className="container py-5">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3 m-1">
                    {images.map((image, index) => (
                    <div key={index} className="col">
                        <div className="card text-decoration-none border border-0" id='card-decs'>
                            <img src={image.src} className="card-img-top h-100 card-desc-img text-decoration-none" alt={image.alt} />
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}