import React, {useState} from "react";
import Logo from '../../assets/logo/blacklogo.png'
import './NavbarStyles.css';
import {BsFacebook, BsInstagram, BsTiktok} from 'react-icons/bs';
import {AiOutlineClose } from 'react-icons/ai';
import {HiOutlineMenuAlt3} from 'react-icons/hi';
import { FaPinterest } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Navbar(){
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => {
      setIsNavCollapsed(!isNavCollapsed);
    };
    const [color, setColor] = useState(false)
    const changeColor =() => {
        if(window.scrollY >= 100){
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)

    return(
        <nav className={color? "navbar navbar-bg navbar-expand-lg fixed-bottom" : "navbar fixed-bottom navbar-expand-lg"}>
            <div className="container">
                <Link to="/" className="navbar-brand">
                    <img 
                        src={Logo} 
                        alt="" 
                        className="navbar-logo-img"
                    />
                </Link>
                <button
                    className="navbar-toggler border border-white text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    data-bs-boundary="document"
                    aria-controls="navbarNav"
                    aria-label="Toggle navigation"
                    aria-expanded={!isNavCollapsed ? true : false}
                    onClick={handleNavCollapse}
                    style={{ marginRight: "0.5rem", backgroundColor: '#d9a95b', borderRadius: '50%', padding: '10px'}}
                >
                    <span className="menu-icons text-white">{isNavCollapsed ? <HiOutlineMenuAlt3 /> : <AiOutlineClose />}</span>
                </button>
                <div
                    className={`${
                    isNavCollapsed ? "collapse" : ""
                    } navbar-collapse justify-content-center`}
                    id="navbarNav"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item navbar-link">
                            <Link to='/' className="nav-link fs-4" id="nav-link-hover">Főoldal</Link>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to="/aboutus" className="nav-link fs-4" id="nav-link-hover">Rólam</Link>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to='/kozep' className="nav-link fs-4" id="nav-link-hover">Kollekció</Link>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to='/contact' className="nav-link fs-4" id="nav-link-hover">Kapcsolat</Link>
                        </li>
                    </ul>
                </div>
                <div
                    className={`${
                        isNavCollapsed ? "d-none d-lg-block" : ""
                    } social-icons`}
                >
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100088272905136">
                        <BsFacebook className="fs-2 social-icon" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/_christineart_?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr&fbclid=IwAR1VrFGgi8NBhwN7mDnUvKoV79tT9HMbOntT2sKKIeLW9DRhcQqbUQ7w42s">
                        <BsInstagram className="fs-2 social-icon" />
                    </a>
                    <a target="_blank" href="https://hu.pinterest.com/christinetaylorart/?invite_code=8d888ca1af27488b86110627f4fd7ebb&sender=871165259078091733">
                        <FaPinterest className="fs-2 social-icon" />
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@christinetaylorar?_t=8hWNDr5bono&_r=1&fbclid=IwAR0EMDZXJYEayyIA_lnX6j4v8cStu3usNY_gEX9e0wpKRYsptkLCqzNQZsM">
                        <BsTiktok className="fs-2 social-icon" />
                    </a>
                </div>
            </div>
        </nav>
    )
}