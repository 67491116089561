import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './CookieStyles.css'
import { Link } from 'react-router-dom';


export default function CookieBar(){
    const [cookies, setCookie] = useCookies(['cookieConsent']);
    const [showCookieBar, setShowCookieBar] = useState(
        !cookies.cookieConsent && !sessionStorage.getItem('cookieBarDisplayed')
    );
  
    useEffect(() => {
        if (cookies.cookieConsent || sessionStorage.getItem('cookieBarDisplayed')) {
            setShowCookieBar(false);
        }
    }, [cookies.cookieConsent]);
  
    const handleCookieConsent = () => {
        console.log('Alapvető adatok gyűjtése...');
        setCookie('cookieConsent', true, { path: '/' });
        sessionStorage.setItem('cookieBarDisplayed', 'true');
        setShowCookieBar(false);
    };
  
    const handleCookieReject = () => {
        sessionStorage.setItem('cookieBarDisplayed', 'true');
        setShowCookieBar(false);
    };

    return (
        showCookieBar && (
            <div className="cookie-bar">
              <p>
                Kedves Látogató! Tájékoztatjuk, hogy a honlap felhasználói élmény fokozásának érdekében sütiket alkalmazunk. <br /> A holnapunk használatával ön a tájékoztatásunkat tudomásul veszi.
              </p>
                <button className="accept-button" onClick={handleCookieConsent}>Elfogadom</button>
                <button className="reject-button" onClick={handleCookieReject}>Elutasítom</button>
                <Link to="/privacypolicy" className="policy-button text-decoration-none" 
                >Adatvédelmi irányelvek.</Link>
            </div>
        )
    );
}