import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './RentCollectionStyles.css';

export default function RentCollection() {
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        const fetchCollections = async () => {
            const db = getFirestore();
            const collectionsRef = collection(db, 'yourCollectionName'); // Replace 'yourCollectionName' with your actual collection name

            try {
                const snapshot = await getDocs(collectionsRef);
                const collectionsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCollections(collectionsData);
            } catch (error) {
                console.error('Error fetching collections: ', error);
            }
        };

        fetchCollections();

        const sr = ScrollReveal({
            origin: 'top',
            distance: '80px',
            duration: 2000,
            reset: true
        });

        sr.reveal('.rentcoll-title', { delay: 200 });
        sr.reveal('#rent-car-cheap', { delay: 300 });
        sr.reveal('#rent-car-medium', { delay: 480 });
        sr.reveal('#rent-car-luxury', { delay: 300 });
    }, []);

    return (
        <div className="rentcollection py-5">
            <div className="container my-5">
                <h3 className='rentcoll-title'>Kollekciók</h3>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-3">
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/olcso">
                            <div className="card shadow" id="rent-car-olcso">
                                <img src="olcso_image_url" className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                    <h5 className="rentcollection-title">Olcso Collection</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/kozep">
                            <div className="card shadow border-0" id="rent-car-kozep">
                                <img src="kozep_image_url" className="card-img-top cars-image" alt="" />
                                <div className="card-body text-decoration-none">
                                    <h5 className="rentcollection-title">Kozep Collection</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}