import React from "react";
import {BiMenuAltRight} from 'react-icons/bi'
import Logo from '../../assets/logo/hero-logo.png'
import { Link } from "react-router-dom";

export default function DashNav({handleLogout, handleOpenMenu}) {

    return (
        <header className="navbar bg-dark p-0 shadow flex-md-nowrap fixed-bottom" data-bs-theme="dark">
            <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" to="/" >
                <img 
                    src={Logo} 
                    alt="" 
                    className="navbar-logo-img"
                />
            </Link>
            <div className="mx-2">
                <button 
                    className="btn" 
                    onClick={handleLogout}
                    style={{
                        backgroundColor:' #d9a95b',
                        borderRadius:'25px 25px 25px 25px'
                    }}
                >Kijelentkezés</button>
            </div>
            <ul className="navbar-nav flex-row d-md-none mx-1" style={{backgroundColor:'#212529'}}>
                <li className="nav-item text-nowrap">
                    <button 
                        className="nav-link" 
                        type="button" 
                        style={{backgroundColor:'#d9a95b', borderRadius:'5px 5px 5px 5px', padding:'5px'}}
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#sidebarMenu" 
                        aria-controls="sidebarMenu" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                        onClick={handleOpenMenu}
                    ><BiMenuAltRight className="text-white" size={30} /></button>
                </li>
            </ul>
        </header>
    );
}

