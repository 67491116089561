import React, { useEffect, useState } from "react";
import LoadingImg from '../../assets/loading/animation_sync_Contrast_jqi660bqe (2).gif';
import './LoadingStyles.css';

export default function Loading({loading , setLoading}) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3800);
    return () => clearTimeout(timeoutId);
  }, []); 

  return (
    <div className="loading">
      <div className="container">
        <div className="wrapper">
          <img src={LoadingImg} alt="" />
        </div>
      </div>
    </div>
  );
}