import React from 'react';
import DashAddSettings from '../dashcustomers/DashAddSettings';
export default function DashCustomers(){
    return(
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="container pt-5 my-5">
                <DashAddSettings/>
            </div>
        </main>
    )
}