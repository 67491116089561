import React, {useEffect} from 'react';
import './NextInformationStyles.css'
import ScrollReveal from 'scrollreveal';
import Painting from '../../assets/gallery/painting1.jpg';
import Painting1 from '../../assets/gallery/painting2.jpg';
import Painting2 from '../../assets/gallery/painting3.jpg';

export default function NextInformation(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 3000,
          reset: false 
        });
        sr.reveal('.next-time', {delay: 230, origin:'right'});
        sr.reveal('.nextinformation-hr', {delay: 250, origin:'left'});
        sr.reveal('.next-description', {delay: 300, origin:'right'});
        sr.reveal('#next-information-group1', {delay: 330, origin:'left'});
        sr.reveal('#next-information-group2', {delay: 365, origin:'left'});
        sr.reveal('#next-information-group3', {delay: 400, origin:'left'});
        return () => {
          sr.destroy();
        };
    }, [])
      
    return(
        <div className="nextinformation py-5">
            <div className="container px-4 py-5" id="featured-3">
                <h2 className="pb-2 text-center next-title">További információk</h2>
                <hr className="nextinformation-hr" />
                <p className="text-center next-description">Eredeti festményeim időtlen szépséget hoznak otthonodba és munkahelyedre.</p>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 text-center mt-5">
                    <div className="feature col" id="next-information-group1">
                        <div className="nextinformation-img-group d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                            <img className="nextinformation-img" src={Painting} alt="" />
                        </div>
                        <h3 className="fs-2 text-body-emphasis next-title">Egyedi Művészetei <br />Élmények</h3>
                        <p className="next-description">Az összes festményem eredeti és egyedi kialakítású, melyeket prémium minőségű olasz vásznakból, akril festékekből és metál fóliákból készítem.</p>
                    </div>
                    <div className="feature col" id="next-information-group2">
                        <div className="nextinformation-img-group d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                            <img className="nextinformation-img" src={Painting1} alt="" />
                        </div>
                        <h3 className="fs-2 text-body-emphasis next-title">Absztrakt Varázslat az otthonában</h3>
                        <p className="next-description">Absztrakt festményeim tökéletesek céges terekbe vagy otthonokba, mivel minden enteriőrbe időtlen szépséget visznek.</p>
                    </div>
                    <div className="feature col" id="next-information-group3">
                        <div className="nextinformation-img-group d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                            <img className="nextinformation-img" src={Painting2} alt="" />
                        </div>
                        <h3 className="fs-2 text-body-emphasis next-title">Színek, Kreativitás, Egyediség</h3>
                        <p className="next-description">Weboldalamon minden elérhető munkámat megtalálod, de szívesen vállalok egyedi megbízásokat is, ami színben passzol az enteriőrhöz.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}