import React from 'react';
import { Link } from 'react-router-dom';
import { BsTiktok, BsFacebook, BsInstagram } from 'react-icons/bs';
import Logo1 from '../../assets/logo/logo1.png';
import { FaPinterest } from 'react-icons/fa';
import './FooterStyles.css';

export default function Footer() {
    return (
        <div className='footer'>
            <div className='container'>
                <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 my-4 footer'>
                    <Link to='/' className='col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 order-1 order-md-2 order-lg-2'>
                        <img src={Logo1} alt='' className='footer-logo' />
                    </Link>
                    <ul className='nav col-12 col-md-4 col-lg-4 justify-content-center text-center order-2 order-md-3 order-lg-3'>
                        <li className='nav-item'>
                            <Link to='/' className='nav-link px-2 text-black'>Főoldal</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/aboutus' className='nav-link px-2 text-black'>Rólam</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/kozep' className='nav-link px-2 text-black'>Kollekció</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/kapcsolat' className='nav-link px-2 text-black'>Kapcsolat</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/privacypolicy' className='nav-link px-2 text-black'>Adatvédelmi szabályzat</Link>
                        </li>
                    </ul>
                    <div className='col-12 col-md-4 col-lg-4 text-center mb-3 mb-md-0 order-3 order-md-1 order-lg-1'>
                        <a target='_blank' href='https://www.facebook.com/profile.php?id=100088272905136'>
                            <BsFacebook className='fs-2 social-icon1 mx-2' />
                        </a>
                        <a
                        target='_blank'
                        href='https://www.instagram.com/_christineart_?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr&fbclid=IwAR1VrFGgi8NBhwN7mDnUvKoV79tT9HMbOntT2sKKIeLW9DRhcQqbUQ7w42s'
                        >
                            <BsInstagram className='fs-2 social-icon1 mx-2' />
                        </a>
                        <a
                        target='_blank'
                        href='https://hu.pinterest.com/christinetaylorart/?invite_code=8d888ca1af27488b86110627f4fd7ebb&sender=871165259078091733'
                        >
                            <FaPinterest className='fs-2 social-icon1 mx-2' />
                        </a>
                        <a
                        target='_blank'
                        href='https://www.tiktok.com/@christinetaylorar?_t=8hWNDr5bono&_r=1&fbclid=IwAR0EMDZXJYEayyIA_lnX6j4v8cStu3usNY_gEX9e0wpKRYsptkLCqzNQZsM'
                        >
                            <BsTiktok className='fs-2 social-icon1 mx-2' />
                        </a>
                    </div>
                </footer>
                <p className="text-center">Copyright &copy; 2023 Christinetaylorart. Minden jog fentartva.</p>
            </div>
        </div>
    );
}