import React from 'react';
import TutorialVideo from '../tutorial/TutorialVideos';


export default function DashTutorial(){
    return(
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-5 my-5">
            <TutorialVideo />
        </main>
    )
}