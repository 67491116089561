import React, { useEffect} from 'react';
import {BsFacebook, BsInstagram, BsTiktok, BsFillTelephoneFill, BsPinterest} from 'react-icons/bs';
import {ImLocation} from 'react-icons/im';
import {FaPinterest} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md';
import ScrollReveal from 'scrollreveal';
import './ContactStyles.css';
import ComForm from './ConForm';

export default function ContactForm(){

    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'top',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
    
        sr.reveal('.contactform-title-h2', { delay: 200, origin: 'right' });
        sr.reveal('.contactform-title', { delay: 220, origin: 'right' });
        sr.reveal('.contactform-hr', { delay: 240, origin: 'right' });
        sr.reveal('#contactform-location', { delay: 270, origin: 'right' });
        sr.reveal('#contactform-phone', { delay: 300, origin: 'right' });
        sr.reveal('#contactform-email', { delay: 340, origin: 'right' });
        sr.reveal('#name-form', { delay: 200, origin: 'left' });
        sr.reveal('#phone-form', { delay: 240, origin: 'left' });
        sr.reveal('#email-form', { delay: 370, origin: 'left' });
        sr.reveal('#message-form', { delay: 330, origin: 'left' });
        sr.reveal('.contactform-btn', { delay: 390, origin: 'left' });
        sr.reveal('.contactform-address', { delay: 380, origin: 'right' });
        sr.reveal('.contact-social-group', { delay: 390, origin: 'right' });
        sr.reveal('#contact-icons1', { delay: 340, origin: 'right' });
        sr.reveal('#contact-icons2', { delay: 360, origin: 'right' });
        sr.reveal('#contact-icons3', { delay: 380, origin: 'right' });
        sr.reveal('#contact-icons4', { delay: 400, origin: 'right' });
        return () => {
          sr.destroy();
        };
      }, [])


    return(
        <div className='contactform-group py-5'> 
            <div className='container col-xxl-7'>
                <div className="row justify-content-center py-3 align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <ComForm />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div>
                            <div className="addres">
                                <h2 className='contactform-title-h2'>További információra <br />van szüksége?</h2>
                                <h5 className='py-1 contactform-title'>Lépj velünk kapcsolatba</h5>
                                <hr className="contactform-hr" />
                                <div className='contactform-contact-group'>
                                    <div className="d-grid py-3" id="contactform-location">
                                        <span className="fs-3 icon-link icon-link-hover" style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}>
                                            <ImLocation className="bi contact-icons"/>
                                        </span>        
                                        <span className='contactform-addres'>Tartozkodási hely:</span>
                                        <span className='addres-title'>Magyarország, Budapest</span>
                                    </div>
                                    <div className="d-grid py-1" id="contactform-phone">
                                        <span className='fs-3 icon-link icon-link-hover' style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}>
                                            <BsFillTelephoneFill className="bi contact-icons" />
                                        </span>
                                        <span className='contactform-addres'>Telefonszám:</span>
                                        <a className='text-decoration-none addres-title' href="tel:+36702226217">+36-70-222-6217</a>
                                    </div>
                                    <div className="d-grid py-1" id="contactform-email">
                                        <span className="fs-3 icon-link icon-link-hover" style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}>
                                            <MdEmail className="bi contact-icons" />
                                        </span>
                                        <span className='contactform-addres'>Email cím:</span>
                                        <a className='text-decoration-none addres-title' href="mailto:info@christinetaylorart.com">info@christinetaylorart.com</a>
                                    </div>
                                </div>
                            </div>        
                            <div className="py-2 contact-social-media">
                                <span className='contactform-address'>Közösségi média:</span>
                                <div classame="d-flex justify-content-around social-icon-group">
                                    <a 
                                        href="https://www.facebook.com/profile.php?id=100088272905136" 
                                        className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                        style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                        id="contact-icons1"
                                    >
                                        <BsFacebook className="bi contact-icons"/>
                                    </a>
                                    <a 
                                        href="https://www.instagram.com/_christineart_?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr&fbclid=IwAR1VrFGgi8NBhwN7mDnUvKoV79tT9HMbOntT2sKKIeLW9DRhcQqbUQ7w42s" 
                                        className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                        style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                        id="contact-icons2" 
                                    >
                                        <BsInstagram className="bi contact-icons"/>
                                    </a>
                                    <a 
                                        href="https://hu.pinterest.com/christinetaylorart/?invite_code=8d888ca1af27488b86110627f4fd7ebb&sender=871165259078091733" 
                                        className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                        style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                        id="contact-icons3" 
                                    >
                                        <BsPinterest className="bi contact-icons"/>
                                    </a>
                                    <a 
                                        href="https://www.tiktok.com/@christinetaylorar?_t=8hWNDr5bono&_r=1&fbclid=IwAR0EMDZXJYEayyIA_lnX6j4v8cStu3usNY_gEX9e0wpKRYsptkLCqzNQZsM" 
                                        className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                        style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                        id="contact-icons4"
                                    >
                                        <BsTiktok className="bi contact-icons"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}