import React from 'react';
import './BookingStyles.css';
import {AiOutlineClose} from 'react-icons/ai';
import { useForm, ValidationError } from '@formspree/react';

export default function Book({product, toggleMenu}) {
    const [state, handleSubmit] = useForm("mleqrwbj");
    if (state.succeeded) {
        return <p>Sikeresen elküldtük az űrlapot!</p>;
    }
    const paintingName = product.name; 

    return (
        <div className="modal-background">
            <div className="container">
                <div>
                    <div className="card card-booking">
                        <form onSubmit={handleSubmit} className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="col">
                                    <div className="form-floating mb-3" style={{fontFamily:'Abereto', fontSize:'20px'}}>
                                        {paintingName}
                                        <input
                                            type="hidden"  
                                            name="carName"
                                            value={paintingName} 
                                        />
                                    </div>
                                </div>
                                <div className="col text-end mb-3 fs-3">
                                    <AiOutlineClose onClick={toggleMenu} />
                                </div>
                            </div>
                            <div className="d-grid g-3">
                                <div className="row g-2">
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                name="phone"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Telefonszám</label>
                                            <ValidationError
                                                prefix="phone"
                                                field="phone"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                name="name"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Név</label>
                                            <ValidationError
                                                prefix="name"
                                                field="name"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        name="email"
                                        required
                                    />
                                    <label htmlFor="floatingInput">Email címe</label>
                                    <ValidationError
                                        prefix="email"
                                        field="email"
                                        errors={state.errors}
                                    />
                                </div>
                                <div className="form-floating">
                                    <textarea
                                        className="form-control"
                                        id="floatingTextarea2"
                                        name="message"
                                        placeholder="Kérdés / kérés"
                                        style={{
                                            maxHeight: '250px',
                                            minHeight: '200px',
                                        }}
                                    ></textarea>
                                    <ValidationError
                                        prefix="message"
                                        field="message"
                                        errors={state.errors}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start mx-1">
                                <div>
                                    <input 
                                        type="checkbox"
                                        id="acceptTerms"
                                        name="acceptTerms"
                                        required
                                    /> <span htmlFor="floatingInput">Elfogadom az adatkezelési tájékoztatót.</span>
                                </div>
                                <div className="form-floating">
                                    <button
                                        type="submit"
                                        disabled={state.submitting}
                                        className="button-unique px-3 p-1"
                                    >
                                        Küldés
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}