import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import AboutUs from '../components/aboutus/AboutUs';
import AboutGallery from '../components/aboutus/AboutGallery';
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import CompanyAbout from '../components/companydetail/CompanyAbout';
export default function About(){


    return(
        <>
            <Navbar />
            <BreadCrumb />
            <AboutUs />
            <CompanyAbout />
            <AboutGallery/>
            <Footer />
        </>
    )
}