import React, { useEffect, useState } from "react";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import DashEdited from "./DashEdited";

export default function DashAddCustomers({ searchText }) {
  const [vehicleData, setVehicleData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);

  const togglePopup = (data) => {
    setPopupOpen(!isPopupOpen);
    setSelectedVehicleData(data);
  };

  const handleDelete = async (id) => {
    try {
      const collectionNames = ["felso", "kozep", "olcso"];
      for (const collectionName of collectionNames) {
        const docRef = doc(db, collectionName, id);
        await deleteDoc(docRef);
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const fetchData = async () => {
    const combinedData = [];
    const collections = ["felso", "kozep", "olcso"];
    for (const collectionName of collections) {
      const dataCollection = collection(db, collectionName);
      const dataSnapshot = await getDocs(dataCollection);
      dataSnapshot.forEach((doc) => {
        if (!doc.data().deleted) {
          combinedData.push({ id: doc.id, ...doc.data() });
        }
      });
    }
    setVehicleData(combinedData.reverse());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterData = () => {
    if (!searchText) {
      return vehicleData;
    }

    const lowerCaseSearchText = searchText.toLowerCase();
    return vehicleData.filter((data) =>
      data.name.toLowerCase().includes(lowerCaseSearchText)
    );
  };

  return (
    <div>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="overflow-x-auto">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Termék neve</th>
                  <th scope="col">Eredeti ár</th>
                  <th scope="col">Akciós Ár</th>
                  <th scope="col">Méret</th>
                  <th scope="col">Szerkesztés</th>
                  <th scope="col">Törlés</th>
                </tr>
              </thead>
              <tbody>
                {filterData().map((data) => (
                  <tr key={data.id}>
                    <td>{data.name}</td>
                    <td>{data.country} Ft</td>
                    <td>{data.state} Ft</td>
                    <td>{data.paintingsize}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => togglePopup(data)}
                      >
                        Szerkesztés
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(data.id)}
                      >
                        Törlés
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isPopupOpen && selectedVehicleData && (
        <DashEdited togglePopup={togglePopup} selectedVehicleData={selectedVehicleData} />
      )}
    </div>
  );
}