import React, {useEffect} from 'react';
import './CompanyStyles.css';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';

export default function CompanyQestions(){

  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'right',
      distance: '80px',
      duration: 2000,
      reset: false // Ne állítsa vissza az animációkat a görgetés után
    });
    sr.reveal('.company-title', {delay: 250, origin:'right'});
    sr.reveal('.company-description-p', {delay: 300, origin:'left'});
    sr.reveal('#button-collection', {delay: 380, origin:'right'});
    return () => {
      sr.destroy();
    };
  }, [])

  return(
    <div className='company-description py-5'>
      <div className='container py-5 my-5'>
        <div className="company-group py-4">
          <h1 className="company-title">Kérdése van?</h1>
          <p className='company-description-p'>Vegye fel velem a kapcsolatot!</p>
          <Link to="/contact" className="button-unique" id='button-collection'>Kapcsolat</Link>
        </div>
      </div>
    </div>
  )
}