import React from 'react';
import './PrivacyStyles.css'
import PolicyText from './PolicyText';
import Table from './Table';

export default function Policy(){
    return(
        <div>
            <div className="container col-xxl-7 py-5">
                <h1 className="text-center py-5 first-title">Adatvédelmi szabályzat</h1>
                <div>
                    <div>
                        <p>Az Ön által megadott személyes adatokat kezeli:</p>
                    </div>
                    <div>
                        <p className='privacy-description'><span className='privacy-bold'>Vállalkozó/Vállalkozás neve/Tulajdonos neve: </span>Sólyom Krisztina</p>
                        <p className='privacy-description'><span className='privacy-bold'>Székhely: </span>1015 Budapest, Toldy Ferenc utca 1/B</p>
                        <p className='privacy-description'><span className='privacy-bold'>Adószám: </span>56744583-1-41</p>
                        <p className='privacy-description'><span className='privacy-bold'>Nyilvántartási szám: </span>55422338</p>
                    </div>
                    <div>
                        <p className='privacy-description'>Nyilvántartásba bejegyző bíróság: CX Törvényszék Cégbírósága - a továbbiakban: <span className='privacy-bold'>Adatkezelő.</span></p>
                    </div>
                    <div>
                        <p className='privacy-description'>Kérjük, figyelmesen olvassa el jelen adatkezelési tájékoztatónkat (a továbbiakban:<span className='privacy-bold'> Tájékoztató),</span> melyben személyes adatai kezelését érintő gyakorlatunkat ismertetjük az Általános Adatvédelmi Rendelet (a GDPR, azaz az Európai Parlament és Tanács 2016/679. rendelete) szerint. Ez a Tájékoztató az Adatkezelő szolgáltatásait fogyasztóként igénybe vevő személyekre <span className='privacy-bold'>(Ön),</span> vonatkozik. Bemutatja, hogy az Adatkezelő hogyan gyűjti, használja fel és osztja meg bizonyos esetekben harmadik felekkel az Ön személyes adatait, továbbá információval szolgál az Ön adatkezeléssel összefüggő „érintetti” jogairól.</p>
                    </div>
                    <div>
                        <p className='privacy-description'>Az Adatkezelő elkötelezett e honlapot felkereső egyének személyes adatainak és magánéletének védelme iránt. Jelen tájékoztató és nyilatkozat mindenekelőtt az ügyfelek és hírlevél-feliratkozók személyes adatainak interneten keresztül történő bizalmas kezeléséről és védelméről szól, mindazonáltal ide tartoznak az oldal látogatói is. Összefoglaljuk, hogy a tulajdonos milyen Önre vonatkozó adatokat hogyan gyűjthet és használhat fel, illetve milyen módon használhatják fel. Az adatok illetéktelen személyek részére nem kerülnek átadásra és ezen tájékoztatóban meghatározott formában kerülnek felhasználásra.</p>
                    </div>
                    <div>
                        <p className='privacy-description my-2'>A szerződésekben egyedi esetekben az adatkezelés célja módosulhat, mely az adott szerződésben rögzített feltételekkel történik.</p>
                        <p className='privacy-description my-2'>A tájékoztató fontos információt nyújt Önnek személyes adatai védelméről és ehhez fűződő jogairól. Ha a felhasználó nem fogadja el ezen feltételeket, jogában áll a honlap használatát felfüggeszteni és személyes adat megadása nélkül a böngészést befejezni.</p>
                    </div>
                    <div className="py-4">
                        <h5 className="privacy-title">ADATKEZELŐ FELELŐSSÉGE ÉS ELÉRHETŐSÉGE</h5>
                        <p className="privacy-title">1.1 Adatkezeléssel kapcsolatos információk</p>
                    </div>
                    <div>
                        <p className='privacy-description'>Jelen Tájékoztatóban ismertetett adatkezelések tekintetében Sólyom Krisztina az adatkezelő (a továbbiakban: <span className='privacy-bold'>Adatkezelő</span>)</p>
                        <p className='privacy-description'>Adatvédelmi eljárásainkat érintő észrevétel, kérdés vagy panasz esetén az alábbi elérhetőségeken vagyunk elérhetőek: Sólyom Krisztina, 1015 Budapest, Toldy Ferenc u. 1/B, info@christinetaylorart.com </p>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Table />
                    </div>
                    <div>
                        <p className='privacy-description'>Választásának megfelelően Ön személyes adatai megadása nélkül is jogosult hozzáférni weboldalainkhoz, viszont a böngészéshez elengedhetetlen a süti - cookie szabályzat elfogadása, hírlevél-feliratkozás esetén pedig az adatvédelmi tájékoztató és nyilatkozat jóváhagyása. Személyes adat az összes információ, amely közvetett vagy közvetlen módon, azonosító szám szerint vagy az ehhez tartozó adatok alapján azonosítható természetes személyre vonatkozik. Abban az esetben, hogyha az oldal Tulajdonosa az Ön személyes adatait gyűjti, az adatgyűjtés átlátható és bizalmas adatkezeléssel történik. </p>
                        <p className='privacy-description'>Az Ön személyes adatai olyan információk, melyek segítségével az Ön személye beazonosítható, például: vezetéknév, utónév, állampolgárság, telefonszám, postacím, e-mail cím. Az Ön személyes adatait önkéntesen megadott módon, a tulajdonos az oldalon történő böngészéskor gyűjti, megjelölve minden esetben a cél eléréséhez szükségszerűen megadandó adatokat. Ha nem kívánja megadni ezen adatokat, akkor nem férhet hozzá a honlap egyes funkcióihoz, szolgáltatásaihoz. Az opcionálisan megadandó adatok gyűjtése a felhasználói igények megismerését, a honlap és szolgáltatások fejlesztését szolgálja.</p>
                        <p className='privacy-description'>A Tulajdonos minden intézkedést megtesz, hogy az általa kezelt személyes adatok pontosak, naprakészek legyenek.</p>
                    </div>
                    <div>
                        <p className="privacy-title">Az alábbi személyes adatok kezelése történik:</p>
                        <ul className='privacy-list'>
                            <li>Vezetéknév, keresztnév, cégnév és kapcsolattartó neve (cég esetén)</li>
                            <li>Székhely címe (cég esetén), lakcím, levelezési cím</li>
                            <li>E-mail cím</li>
                            <li>Telefonszám</li>
                            <li>Rendszer információk (IP-cím, rendszer verzió, felbontás, statisztika a honlapon megtekintett oldalakról, böngészési szokások, viselkedési mintázat)</li>
                        </ul>
                    </div>
                    <div>
                        <p className="privacy-title">Adatkezelés időtartama:</p>
                        <ul className='privacy-list'>
                            <li>Direkt marketing célú hozzájárulások tekintetében a felhasználó hozzájárulásának visszavonásáig</li>
                            <li>Profiladatok tekintetében az utolsó bejelentkezéstől számított 4 év</li>
                            <li>Vásárlások adatai tekintetében a Számv. tv. 169. § (2) bekezdése alapján 8 év</li>
                        </ul>
                    </div>
                    <div>
                        <p className="privacy-title">Adattovábbítás feltételei</p>
                        <p className='privacy-description'>Az Adatkezelő az általa kezelt adatokat - a szükséges mértékben - továbbíthatja a következő területeken tevékenykedő, általa kijelölt személyek, valamint társaságok részére:</p>
                        <ul className='privacy-list'>
                            <li>Adatfeldolgozás</li>
                            <li>Jogi képviselet</li>
                            <li>Jogviták kezelésére jogszabály alapján jogosult szervek</li>
                            <li>Kézbesítés</li>
                            <li>Könyvelés</li>
                            <li>Követeléskezelés</li>
                            <li>Marketing</li>
                            <li>Számlázás</li>
                        </ul>
                    </div>
                    <div>
                        <p className="privacy-title">Adatfeldolgozók</p>
                        <p className='privacy-description'>Webáruházi vásárláskor az Adatkezelő (a Felhasználó által megjelölt szállítási mód alapján) a Felhasználó nevét, szállítási címét (Futárszolgálat esetén e-mail címét és telefonszámát - a kiszállítás időpontjának egyeztetése érdekében), továbbá a termék beszedendő (esetenként előre kiegyenlített) vételárával kapcsolatos információt átadja a kiszállítást végző cég számára, a termék Felhasználó részére történő kézbesítése céljából.</p>
                    </div>
                    <div>
                        <p className="privacy-descrption text-decoration-underline">Az Adatkezelő által használt futárszolgálatok (adatfeldolgozók):</p>
                        <ul>
                            <li>
                                Futárszolgálat vagy GLS pont választása esetén:
                                <span className='privacy-bold'>GLS General Logistics Systems Hungary Csomag-Logisztikai Kft.</span>
                                (székhelye: 2351 Alsónémedi, GLS Európa u. 2., cégjegyzékszáma: 13-09-111755, adószáma: 12369410-2-44)
                            </li>
                            <li>
                                Postai kiszállítás esetén:
                                <span className='privacy-bold'> Magyar Posta Zrt.</span> (székhelye: 1138 Budapest, Dunavirág u. 2-6., cégjegyzékszáma: 01-10-042463, adószáma: 10901232-2-44)
                            </li>
                        </ul>
                        <p className="privacy-descrption text-decoration-underline">A Felhasználó elfogadja e hozzájárulás szerint az általa választott kiszállító cég saját Általános Szerződési Feltételeit.</p>
                    </div>
                    <div className="py-4">
                        <h5 className="privacy-title">Adatfeldolgozást végző vagy adatkezelést érintő cégek, személyek</h5>
                    </div>
                    <div>
                        <p className="privacy-descrption text-decoration-underline">A tulajdonos, Sólyom Krisztina végzi az adatok kezelését. A tulajdonoson kívül más cégek is részt vesznek a szerződéses ügyfelek adatainak kezelésében, tárolásában.</p>
                    </div>
                    <div className="py-4">
                        <h5 className="privacy-title">Mely cégek kapcsolódnak az adatkezeléshez?</h5>
                    </div>
                    <div>
                        <p className='privacy-description'>A Tulajdonos tevékenysége során az adatkezelésben érintett cégek:</p>
                        <ul className='privacy-list'>
                            <li>Tárhely szolgáltató: Rackhost Zrt. 6722 Szeged, Tisza krt. 41. Név: cím, Telefon: +36 1 4451200 elérhetőségek –</li>
                            <li>adatvédelmi nyilatkozat link: <a className='text-black' href="https://www.rackhost.hu/privacy-policy?fbclid=IwAR2O1CVDxWHIK9fmr3AAOAzd1409j_F1p2y5iM1gKSz35T6z4Y3ZfLTCXVM">Rackhost Adatvédelmi Szabályzat</a></li>
                            <li><span className='privacy-bold'>Google Inc.:</span>1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, <a href="https://about.google/contact-google/" className='text-decoration-underline text-black'>további elérhetőségek,</a>levelező rendszer, felhőben történő adat és fájl tárolás, online dokumentum kezelés, továbbá a hozzátartozó szolgáltatások: Google Drive, Google Docs, Google Search Console, Google Analytics, Google AdSense, Google AdWords, YouTube, Blogger, Chrome böngésző támogatása - Google <a href="https://support.google.com/a/answer/60762?hl=en" className='text-decoration-underline text-black'>adatvédelme</a>és <a href="https://policies.google.com/privacy?hl=hu" className='text-decoration-underline text-black'>adatvédelem elvei</a></li>
                            <li><span className='privacy-bold'>Facebook Ireland Ltd.:</span>4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland, a Facebook, Instagram, Messenger és Facebook által kínált egyéb termékek, funkciók támogatása - <a href="https://www.facebook.com/about/privacy/update" className='text-black'>adatkezelési szabályzat</a> és adatvédelmi tisztviselő <a href="https://www.facebook.com/help/contact/540977946302970" className='text-decoration-underline text-black'>elérhetősége</a>.</li>
                            <li><span className='privacy-bold'>Twitter International Company:</span> One Cumberland Place, Fenian Street, Dublin 2, D02 AX07 IRELAND, a Twitter által kínált termékek, funkciók használata - <a href="https://twitter.com/en/privacy" className="text-decoration-underline text-black">adatkezelési szabályzat</a> és <a className="text-decoration-underline text-black" href="https://twitter.com/en/tos">ászf</a>.</li>
                            <li>Speciális esetekben a szerződés tartalmazhat további adatfeldolgozó személyeket vagy cégeket.</li>
                        </ul>
                    </div>
                </div>
                <PolicyText/>
            </div>
        </div>
    )    
}