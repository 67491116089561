import React, {useEffect} from "react";
import ContactImage from '../../assets/contact/conimg.jpg';
import ScrollReveal from "scrollreveal";
import { Link } from "react-router-dom";
import './ContactStyles.css';

export default function Contact(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('.img-contact', {delay: 250, origin:'left'});
        sr.reveal('.contact-title', {delay: 200, origin:'right'});
        sr.reveal('.contact-hr', {delay: 230, origin:'right'});
        sr.reveal('.contact-description', {delay: 300, origin:'right'});
        sr.reveal('.contact-description1', {delay: 330, origin:'right'});
        sr.reveal('.btn', {delay: 370, origin:'right'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="contact py-lg-5 py-md-5 py-sm-0">
            <div className="container">
                <div 
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 align-items-center g-4 my-lg-5 my-md-5 my-sm-0"
                    id="rowol"
                >
                    <div className="col">
                        <img 
                            src={ContactImage} 
                            alt=""    
                            className="img-fluid img-contact"
                        />
                    </div>
                    <div className="col" >
                        <div className="text-lg-start contact-text-group" id="colol">
                            <h1 className="contact-title">Rólam</h1>
                            <hr className="contact-hr" />
                            <p className="contact-description">Szabó Krisztina vagyok, autodidakta intuitív festő és a Christine art megalkotója. Tinédzser korom óta a lakberendezés és a belsőépítészet területén kibontakozó szenvedély hajtott és ez a kreatív lángolás most is elevenen ég bennem. A mai napig szívesen tevékenykedek ebben a témakörben, imádom a minimál stílust és a letisztult vonalakat. 2019-ben egy spontán pillanatban született az első absztrakt alkotásom, majd a hobbiból 2023-ban szárnyra kelt a művészi vállalkozásom. A kötöttség sosem volt az én világom, így egy saját teret teremtettem, ahol szabadon kibontakozhatok. Minden festmény egy érzés, egy kis szelet a szívemből, ahol az alkotói szabadság szellemében születnek meg azok a művek, amelyek minden ecsetvonással egy új tanulási kaland részei. A festmények nem csupán vizuális élmények, hanem olyan tükör is, amely a belső ént és a folyamatos tanulási kalandot tükrözik. Egy kreatív utazásra hívok mindenkit, bízva a művészi kifejezés erejében, ahol remélem te is megtalálod a saját világod valamelyik festményem egyikében.</p>
                            <Link
                                to='/contact' 
                                className="btn"
                                style={{
                                    fontFamily:'Aboreto',
                                    backgroundColor:'#d9a95b',
                                    color:'#ffffff',
                                    borderRadius:'25px 25px 25px 25px',
                                }}
                            >Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}