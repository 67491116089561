import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import {
  db,
  storage,
} from '../../firebase';
import {
  addDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';


export default function DashAddPeople({ togglePopup }) {
  const [imageUpload, setImageUpload] = useState([]);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('olcso');
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [paintingsize, setPaintingSize] = useState('');

  const handleImageChange = (e) => {
    const files = e.target.files;
    const uploadedImages = [];

    for (let i = 0; i < files.length; i++) {
      uploadedImages.push(files[i]);
    }

    setImageUpload(uploadedImages);
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    try {
      if (imageUpload.length > 0) {
        const uploadTasks = imageUpload.map((file) => {
          const storageRef = ref(storage, `images/${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {},
              (error) => {
                console.error('Error uploading image:', error);
                reject(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref
                );
                resolve(downloadURL);
              }
            );
          });
        });

        const downloadURLs = await Promise.all(uploadTasks);

        const docRef = await addDoc(collection(db, selectedCollection), {
          name: name,
          country: country,
          state: state,
          paintingsize: paintingsize,
          description: description,
          imageUrls: downloadURLs, 
          timeStamp: serverTimestamp(),
        });

        console.log('Document added with ID:', docRef.id);
        setIsUploadSuccess(true);
      } else {
        const docRef = await addDoc(collection(db, selectedCollection), {
          name: name,
          country: country,
          state: state,
          paintingsize: paintingsize,
          description: description,
          timeStamp: serverTimestamp(),
        });

        console.log('Document added with ID:', docRef.id);
        setIsUploadSuccess(true);
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleCollectionChange = (e) => {
    setSelectedCollection(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePaintingSizeChange = (e) => {
    setPaintingSize(e.target.value)
  }

  return (
    <div className='modal-background py-5'>
      <div className='container'>
        <button onClick={togglePopup} className='btn btn-primary my-2' style={{ float: 'right' }}>
          <AiOutlineClose />
        </button>
        <div className='card card-dashadd'>
          {isUploadSuccess && (
            <div className='alert alert-success mt-2 text-center w-100'>
              <p className='fw-bolder'>Sikeresen feltöltötted az adatokat!</p>
            </div>
          )}
          <form onSubmit={handleAdd} className='card-body'>
            <div className='row g-2'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <input
                  type='file'
                  className='form-control'
                  onChange={handleImageChange}
                  multiple  // Allow multiple file selection
                />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <input className='form-control' type='text' placeholder='Festmény címe' value={name} onChange={handleNameChange} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <input className='form-control' type='text' placeholder='Eredeti Ár' value={country} onChange={handleCountryChange} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <input className='form-control' type='text' placeholder='Akciós Ár' value={state} onChange={handleStateChange} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <input className='form-control' type='text' placeholder='Festmény méret pl: 100X100' value={paintingsize} onChange={handlePaintingSizeChange} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <select className='form-control' value={selectedCollection} onChange={handleCollectionChange}>
                  <option value='olcso'>Válassz Kollekciót</option>
                  <option value='kozep'>Közep</option>
                </select>
              </div>
              <div className='col-12'>
                <textarea
                  className='form-control w-100'
                  style={{
                    maxHeight: '300px',
                    minHeight: '200px',
                  }}
                  value={description}
                  onChange={handleDescriptionChange}
                ></textarea>
              </div>
            </div>
            <button type='submit' className='btn btn-primary mt-2'>
              Hozzáadás
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}