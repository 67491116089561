import React, { useState, useEffect } from "react";
import HeroImage from '../../assets/hero/web2 (1).png';
import HeroImage1 from '../../assets/hero/image0-2-scaled (1).jpeg';
import HeroLogo from '../../assets/logo/hero-logo.png'
import './HeroStyles.css';
import { Link } from "react-router-dom";

const images = [
  HeroImage,
  HeroImage1,
];

export default function Hero() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <div className="slider-container">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`slide ${index === currentIndex ? 'active' : ''}`}
                >
                    <img src={image} alt={`Slide ${index + 1}`} />
                </div>
            ))}
            <div className="slider-text">
                <div className="d-block justify-content-center align-items-center">
                    <img 
                        src={HeroLogo} 
                        className="img-fluid" 
                        alt="" 
                        style={{maxWidth:'400px', minWidth:'200px',}}    
                    />
                    <div className="hero-content">
                        <h1 className="hero-description">Üdvözöllek a <br /> Weboldalamon!</h1>
                        <hr className="hero-hr mb-3 text-center" />
                        <div>
                            <Link className="button-unique text-decoration-none" to="/rentcar/kozep">Kollekció</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}