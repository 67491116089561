import React from 'react';
import './PrivacyStyles.css';

export default function Table(){

    return(
        <div className="py-4">
            <table className="table table-bordered border-black">
                <tbody>
                    <tr className="text-center">
                        <td className="privacy-bold">Adatkezelő</td>
                        <td className="privacy-bold">Felelősségi körök</td>
                    </tr>
                    <tr className="align-items-center">
                        <td className='privacy-description text-center'>Sólyom Krisztina <br /> 1015 Budapest, Toldy Ferenc u. 1/B,</td>
                        <td className='privacy-description text-center'>Összes tevékenység</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}