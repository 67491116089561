import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import SendMessageImage from '../../assets/contact/conimg.jpg'
export default function ComForm(){

    const [state, handleSubmit] = useForm("mqkrzdqe");
    if (state.succeeded) {
        return(
            <div className='card'>
                <div className='card-body'>
                    <div className="d-grid text-center text-success">
                        <h4>Sikeresen elküldte az üzenetet! Köszönjük</h4>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row g-2">
                <div className="col" id="name-form">
                    <div className="form-floating">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="floatingInputValue" 
                            name="name"
                            required
                        />
                        <label className="contactform-lab" htmlFor="floatingInputValue">Teljes Név:</label>
                        <ValidationError 
                            prefix="name" 
                            field="name"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col" id="phone-form">
                    <div className="form-floating">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="floatingInputValue" 
                            name="phone"
                            required
                        />
                        <label className="contactform-lab" htmlFor="floatingInputValue">Telefonszám:</label>
                        <ValidationError 
                            prefix="phone" 
                            field="phone"
                            errors={state.errors}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="form-floating" id="email-form">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="floatingInputValue" 
                        name="email"
                        required
                    />
                    <label className="contactform-lab" htmlFor="floatingInputValue">Email cím:</label>
                    <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="mb-3" id="message-form">
                <div className="form-floating">
                    <textarea 
                        className="form-control" 
                        placeholder="Hagyj üzenetet itt" 
                        id="floatingTextarea2" 
                        name="message"
                        style={{
                            minHeight:'285px',
                            maxHeight:'300px'
                        }}
                    ></textarea>
                    <label className="contactform-lab" htmlFor="floatingTextarea2">Üzenet:</label>
                    <ValidationError 
                        prefix="Message" 
                        field="message"
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="btn-form">
                <button 
                    type='submit' 
                    className='button-unique text-white px-5'
                    id='contactform-btn' 
                    disabled={state.submitting}
                >Küldés</button>
            </div>
        </form>
    );
}